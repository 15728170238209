var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VueTyper',{staticClass:"x fade-in-text",class:{ hide: _vm.isActive },attrs:{"text":[
    'Javascript',
    'Typescript',
    'Vue.js',
    'React.js',
    'Docker',
    'Jest',
    'Node.js',
    'Nest.js' ],"repeat":Infinity,"initial-action":"typing","pre-type-delay":100,"type-delay":100,"pre-erase-delay":1200,"erase-delay":120,"erase-style":"backspace","erase-on-complete":false,"caret-animation":"smooth"}})}
var staticRenderFns = []

export { render, staticRenderFns }