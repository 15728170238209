import Vue from "vue";
import App from "./App.vue";

import "./assets/tailwind.css";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import specific icons */
import {
  faHouseChimneyCrack,
  faPaperPlane,
  faTrain,
  faGraduationCap,
  faCode,
  faIdCardClip,
  faHandshakeAngle,
  faWrench,
  faScrewdriverWrench,
  faMugHot,
} from "@fortawesome/free-solid-svg-icons";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* add icons to the library */
library.add(faHouseChimneyCrack);
library.add(faPaperPlane);
library.add(faTrain);
library.add(faGraduationCap);
library.add(faCode);
library.add(faIdCardClip);
library.add(faHandshakeAngle);
library.add(faWrench);
library.add(faScrewdriverWrench);
library.add(faMugHot);

/* add font awesome icon component */
Vue.component("font-awesome-icon", FontAwesomeIcon);

import "animate.css";

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount("#app");
