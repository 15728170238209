<template>
  <div class="greeting">
    <div class="typer">
      <div class="welcome pt-2">
        My name is Fabian Soosaithasan. I'm a passionate software engineer based
        in <span class="location">Frankfurt, Germany</span>.<br />
        My core competencies include web technologies like
      </div>
      <div class="mt-2">
        <Skills />
      </div>
    </div>
  </div>
</template>

<script>
import Skills from "./Skills.vue";

export default {
  name: "HelloWorld",
  data() {
    return {
      showSkills: false,
      dataReady: false,
      typemachine:
        "Welcome! My name is Fabian Soosaithasan. \nI'm a passionate software engineer with experience in a variety of \nweb technologies like...",
    };
  },
  async mounted() {
    await this.$nextTick();
    await this.$emit("rendered");
  },
  computed: {
    showSkillset() {
      return this.showSkills;
    },
  },
  components: { Skills },
  methods: {
    changeStuff: function () {
      this.showSkills = true;
    },
  },
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/* SCSS */

.greeting {
  // height: 100vh;
  // width: 100%;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .greeting {
    display: flex;
    justify-content: flex-end;
  }
}

.location {
  color: black;
  -webkit-text-fill-color: whitesmoke; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}

.welcome {
  font-size: 25px;
  font-family: "Dosis";
  flex: 2;
  padding-top: 70px;
}

.typer {
  padding: 25px;
  padding-bottom: 0px;
  padding-top: 0px;
  text-align: left;
}

.me-wrapper {
  display: flex;
  justify-content: flex-end;
  // height: 400px;
  width: 100%;
  align-self: flex-end;
}

.me {
  // height: 400px;
  max-width: 80%;
  align-self: end;
}

.plane {
  height: 60px;
  width: 60px;
  margin: 20px;
}

.plane:hover {
  background-color: gray;
}

.vue-typer {
  font-size: 35px;
  font-family: "Dosis";
}
::v-deep .typed {
  color: black !important;
}

::v-deep .x .typed {
  color: rgba(99, 102, 241, 255) !important;
  text-align: right;
}

.getintouch {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
