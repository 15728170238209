import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBYTV7DCSXkDDsswkp1pQzkP-SEAHKzANY",
  authDomain: "fzoozai-me.firebaseapp.com",
  projectId: "fzoozai-me",
  storageBucket: "fzoozai-me.appspot.com",
  databaseURL: "https://fzoozai-me.firebaseio.com",
  messagingSenderId: "519568210501",
  appId: "1:519568210501:web:ebfbca6e53765ec4c5012c",
  measurementId: "${config.measurementId}",
};
const firebaseApp = initializeApp(firebaseConfig);

const db = getFirestore(firebaseApp);

export { db };
