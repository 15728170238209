<template>
  <VueTyper
    :class="{ hide: isActive }"
    :text="[
      'Javascript',
      'Typescript',
      'Vue.js',
      'React.js',
      'Docker',
      'Jest',
      'Node.js',
      'Nest.js',
    ]"
    class="x fade-in-text"
    :repeat="Infinity"
    initial-action="typing"
    :pre-type-delay="100"
    :type-delay="100"
    :pre-erase-delay="1200"
    :erase-delay="120"
    erase-style="backspace"
    :erase-on-complete="false"
    caret-animation="smooth"
  ></VueTyper>
</template>

<script>
import { VueTyper } from "vue-typer";

export default {
  name: "Skills",
  data() {
    return {
      hide: true,
      client: {
        name: "",
      },
    };
  },
  mounted: async function () {
    setTimeout(() => {
      this.hide = false;
    }, 0);
  },
  computed: {
    isActive() {
      return this.hide;
    },
  },
  methods: {},
  components: { VueTyper },
};
</script>
<style>
.hide {
  display: none;
}

.x {
  font-size: 15px;
  font-family: "Dosis";
}

::v-deep .x .typed {
  color: rgb(169, 169, 169) !important;
  text-align: right;
}

.fade-in-text {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 150px;
  color: black;
  animation: fadeIn linear 3s;
  -webkit-animation: fadeIn linear 3s;
  -moz-animation: fadeIn linear 3s;
  -o-animation: fadeIn linear 3s;
  -ms-animation: fadeIn linear 3s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
